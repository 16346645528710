<script setup lang="ts">
import LanguageSwitch from "~/components/Ui/LanguageSwitch.vue"

const localePath = useLocalePath()
const showMenu = ref(false)
const links = [
  {
    path: '/',
    hash: '#funkce',
    text: 'header.function',
    ref: 'funkce',
  },
  {
    path: '/',
    hash: '#faq',
    text: 'header.faq',
    ref: 'faq',
  },
  {
    path: '/',
    hash: '#cenik',
    text: 'header.pricing',
    ref: 'cenik',
  },
  {
    path: '/',
    hash: '#kontakt',
    text: 'header.contact',
    ref: 'kontakt',
  },
]
const linksRef = ref(null)

let sections

const updateMarker = (target: Element, isIntersecting: boolean) => {
  const id = target.id

  if (!id) return

  const el =
    window.innerWidth < 768
      ? document.querySelector('#' + id + 'SpanMobile')
      : document.querySelector('#' + id + 'Span')

  if (!el) return

  if (isIntersecting) {
    el.classList.add('!max-w-full')
  } else {
    el.classList.remove('!max-w-full')
  }
}

onMounted(() => {
  sections = [...document.querySelectorAll('section')]

  const options = {
    root: document,
    rootMargin: '-56px',
    threshold: 0,
  }

  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      updateMarker(entry.target, entry.isIntersecting)
    })
  }, options)

  sections.forEach((section) => {
    observer.observe(section)
  })
})

function openMenu() {
  showMenu.value = true
  document.body.classList.add('overflow-hidden')
}

function closeMenu() {
  showMenu.value = false
  document.body.classList.remove('overflow-hidden')
}

function toggleMenu() {
  if (showMenu.value) {
    closeMenu()
  } else {
    openMenu()
  }
}

function onEnter(el: HTMLElement, done: () => void) {
  el.style.height = '0'
  el.offsetHeight // Trigger a reflow, flushing the CSS changes
  el.style.height = window.innerHeight + 'px'

  el.addEventListener('transitionend', done, { once: true })
}

function onBeforeLeave(el: HTMLElement) {
  el.style.height = el.scrollHeight + 'px'
  el.offsetHeight // Trigger a reflow, flushing the CSS changes
}

function onAfterEnter(el: HTMLElement) {
  // el.style.height = 'auto'
}

function onLeave(el: HTMLElement, done: () => void) {
  el.style.height = '0'

  el.addEventListener('transitionend', done, { once: true })
}
</script>

<template>
  <div
    class="supports-backdrop-blur:bg-white/60 fixed z-50 h-auto w-full bg-white/90 backdrop-blur"
  >
    <div class="border-b">
      <div
        class="container relative z-10 mx-auto flex max-h-14 items-center justify-between py-4"
      >
        <NuxtLink
          class="h-full w-40 text-2xl font-bold"
          aria-label="Home"
          :to="{ path: '/' }"
          @contextmenu.prevent="navigateTo(localePath({ name: 'design-kit' }))"
        >
          <img src="/img/svg/optilynx.svg" class="h-full" alt="" />
        </NuxtLink>
        <div class="hidden gap-4 md:flex">
          <NuxtLink
            v-for="link in links"
            ref="linksRef"
            class="group transition duration-300"
            :to="{ path: localePath(link.path), hash: link.hash }"
          >
            {{ $t(link.text) }}
            <span
              :id="link.ref + 'Span'"
              class="relative -top-1.5 block h-0.5 max-w-0 bg-optilynx-pink transition-all duration-500 group-hover:max-w-full"
            ></span>
          </NuxtLink>
          <LanguageSwitch/>

        </div>
        <div class="h-min w-min md:hidden" @click="toggleMenu()">
          <label for="check" id="menuIcon">
            <span
              class="menu-icon-span line"
              :class="{ dark: false, checked1: showMenu }"
            ></span>
            <span
              class="menu-icon-span line"
              :class="{ dark: false, checked2: showMenu }"
            ></span>
            <span
              class="menu-icon-span line"
              :class="{ dark: false, checked3: showMenu }"
            ></span>
          </label>
        </div>
      </div>
    </div>
    <Transition
      enter-active-class="overflow-hidden transition-[height] duration-200 ease-out"
      leave-active-class="overflow-hidden transition-[height] duration-200 ease-out"
      @enter="onEnter"
      @after-enter="onAfterEnter"
      @before-leave="onBeforeLeave"
      @leave="onLeave"
    >
      <div
        class="supports-backdrop-blur:bg-red-500/60 top-0 z-0 grid h-screen w-full justify-center md:hidden"
        v-show="showMenu"
      >
        <div class="flex flex-col gap-4 pt-16">
          <NuxtLink
            v-for="link in links"
            ref="linksRef"
            class="group w-fit text-2xl transition duration-300"
            :to="{ path: localePath(link.path), hash: link.hash }"
            @click="closeMenu()"
          >
            {{ $t(link.text) }}
            <span
              :id="link.ref + 'SpanMobile'"
              class="relative -top-1.5 block h-0.5 max-w-0 bg-optilynx-pink transition-all duration-500 group-hover:max-w-full"
            ></span>
          </NuxtLink>
          <div class="flex justify-start text-xl">
          <LanguageSwitch/>
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<style></style>

<style scoped>
label {
  display: flex;
  flex-direction: column;
  width: 70px;
  cursor: pointer;
  transform: scale(0.5);
}

label .line {
  background: black;
  border-radius: 10px;
  height: 7px;
  margin: 7px 0;
  transition: all 0.4s cubic-bezier(0.68, -0.6, 0.32, 1.6), background 0ms;
}

.line:nth-of-type(1) {
  width: 50%;
}

.line:nth-of-type(2) {
  width: 100%;
}

.line:nth-of-type(3) {
  width: 75%;
}

input[type='checkbox'] {
  display: none;
}

.checked1 {
  transform-origin: bottom;
  transform: rotatez(45deg) translate(8px, 0px);
}

.checked2 {
  transform-origin: top;
  transform: rotatez(-45deg);
}

.checked3 {
  transform-origin: bottom;
  width: 50% !important;
  transform: translate(30px, -11px) rotatez(45deg);
}

.dark {
  background: #333333 !important;
}
</style>

<script setup lang="ts"></script>

<template>
  <div>
    <UiHeader />
    <div class="pt-[56px]">
      <slot />
    </div>
    <UiFooter />
  </div>
</template>

<style scoped></style>

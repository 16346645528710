<script setup lang="ts">
const { locale } = useI18n()

const props = defineProps<{
  phone?: boolean
}>()

const languageDefinitions = ['cs', 'sk', 'en']

</script>

<template>
  <div class="relative flex group flex-row justify-center cursor-pointer" :class="{'!text-3xl': phone}">
    {{ locale }}
    <div class="group-hover:hidden my-auto pl-1" height="12"/>
    <div class="hidden my-auto pl-1 group-hover:block" height="12"/>
    <div class="absolute w-full group hidden left-0 top-6 z-50 group-hover:block"
         :class="{'top-9': phone}">
      <div class="bg-white py-2 w-max px-4 gap-1 rounded-md flex-col flex mx-auto"
           :class="{'bg-gray-100': phone}"
      >
        <NuxtLink
            v-for="lang in languageDefinitions"
            :key="lang"
            :to="localePath('/', lang)"
            class="link font-bold hover:text-atlantis-green"
            :class="[{'hidden': locale === lang}]"
        >
          {{ lang }}
        </NuxtLink>
      </div>
    </div>
  </div>

</template>

<style scoped lang="scss">

</style>